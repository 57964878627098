import React from 'react'
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table'


const SubList = (props) => {

      const {status, stripe_id, vices} = props

      const renderVices = (vices) => {
        return vices.map((vice, i) => {
          return (
            <tr className="cart-list__row" key={i}>
              <td className="cl-col__text">{vice.name}</td>
              <td className="cl-col__text">${vice.amount}</td>
            </tr>
          )
        })
      }
    
      return(
        <div className='Sublist'>
          <div className='profile__status-container'>
            <div className='profile__section-text profile__sub-id-text'>
              <span>Subscription ID: </span>
               <span>{stripe_id.slice(-7)}</span>
            </div>
            <div className='profile__section-text profile__sub-status-text'>
              <span>Status: </span> 
              <span>{status}</span> 
            </div> 
            <a className='profile__sub-cancel-btn btn btn-primary' 
              href={process.env.NODE_ENV !== 'production' ? 
                "https://billing.stripe.com/p/login/test_7sIg01aBa6W3cKY6oo" : 
                "https://billing.stripe.com/p/login/eVa9Ci1NK6Vq9FucMM"}> 
                Change / Edit
              </a>
          </div>
          <Table striped bordered hover variant="dark">
            <thead>
                <tr className="cl-table-header">
                  <th className="cl-table-header__text">VICE</th>
                  <th className="cl-table-header__text">DONATION</th>
                </tr>
              </thead>
            <tbody>
                {renderVices(vices)}
            </tbody>
          </Table>
        </div>
      )
  



}

const mapStateToProps = (state) => {
  return {
    cart: state.currentUser.vices
  }
}

export default connect(mapStateToProps)(SubList)
