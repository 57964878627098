import React from "react";
import SubList from "./SubList";

const Subscriptions = ({subs}) => {
    if(subs) {
        const subscriptions = subs.map((sub ,i) => {
            const {status, stripe_id, vices} = sub
           return (
             <SubList 
                key={i}
                stripe_id={stripe_id}
                vices={vices}
                status={status}
             />
             )
        })
        return (
            <div className="Subscription-container">
                {subscriptions}
            </div>
        )

    }

}

export default Subscriptions;