import React from "react";
import Vice from "./Vice";
import { addViceToCart } from "../redux/actions/cartActions";
import { connect } from "react-redux";

const CategorySection = (props)=> {
    const {vices, name, description} = props

    const handleClick = vice => {
        props.addViceToCart(vice);
        props.handleShow()
    };

    const renderVices = () => {
        return vices.map(vice => (
        <Vice key={vice.id} handleClick={handleClick} {...vice} />
        ));
    };

    return(
        <div className="CategorySection">
            <div className="CategorySection__title">{name}</div>
            <div className="CategorySection__subtitle">{description}</div>
            <div className="CategorySection__vices">
                {renderVices()}
            </div>
        </div>
    )
}


const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = {
  addViceToCart: addViceToCart
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategorySection);
