import React, {useEffect} from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";

import SigninPage from "./pages/SigninPage";
import NavBar from "./components/NavBar";
import HomePage from "./pages/HomePage";
import Cart from "./pages/Cart";
import UserProfile from "./pages/UserProfile";
import VicePage from "./pages/VicePage";
import { connect } from "react-redux";
import { getVices } from "./redux/actions/viceActions";

import { loadStripe } from "@stripe/stripe-js";
import { signup } from "./redux/actions/userActions";
import ReactGA from "react-ga4";

// For Google Analytics
const MEASUREMENT_ID = "G-DYERVZ2QZB"; // OUR_MEASUREMENT_ID
ReactGA.initialize(MEASUREMENT_ID);

// STRIPE
export const stripePromise = loadStripe(process.env.NODE_ENV !== 'production' ? 'pk_test_2EdgsZXngY4L8onpPDoMb9xb': 'pk_live_riiWJjmYR10Rx4GdJyJYOA6X');

const App = (props) => {

    useEffect(() => {
      props.getVices();
      ReactGA.send({ 
        hitType: "pageview", 
        page: window.location.pathname + window.location.search
      });
    }, []);

    return (
        <React.Fragment>
          <NavBar />
          <main className="main">
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/signin" element={<SigninPage />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/vices" element={<VicePage />} />
            </Routes>
            <Footer />
          </main>
        </React.Fragment>
    );
}
const mapStateToProps = state => {
  return {
    // vices: state.vices,
    // user: state.currentUser
  };
};

const mapDispatchToProps = {
  getVices: getVices,
  // signup: signup
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
