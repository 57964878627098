import React, { useEffect, useState } from "react";
import {Helmet} from "react-helmet";

// import CreateVice from "../components/CreateVice";
import CatergorySection from "../components/CatergorySection";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";

import ReactGA from "react-ga4";
const ViceContainer = (props) => {
  const[categories, setCategories] = useState([])
  const[filter, setFilter] = useState("All Vices")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetch("api/categories")
      .then(res => res.json())
      .then(categories => {
        setCategories(categories);
      });
  }, [])

  const renderCategories = () => {
    let cats = categories
    if (filter !== "All Vices") {
      cats = categories.filter(
        cat => cat.name === filter
      );
    }
    return cats.map( cat => {
      return (
        <CatergorySection
            key={cat.id}
            name={cat.name} 
            vices={cat.vices} 
            description={cat.description}
            handleShow={handleShow}
          />
      )
    })

  }

  const handleFilterChange = e => {
    setFilter(e.target.value);
  };

    return (
      <div>
        <Helmet>
          <title>Vices - Give it Up for Arts Ed</title>
          <meta name="description" content="Vices - Give it Up for Arts Ed" />
        </Helmet>
        <Modal className="add-to-cart-modal" show={show} onHide={handleClose}>
          <Modal.Header >
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>Vice added to Cart!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Keep Looking
            </Button>
            <Button variant="primary" onClick={() => navigate('/cart')}>
              Go To Cart
            </Button>
          </Modal.Footer>
        </Modal>
        <Container className="pt-5 pb-5">
          <h1 className="page-header page-header--vice-list">Give up a Vice</h1>
          
            <Form className="Vice__category-select pr-3">
              <Form.Control
                as="select"
                value={filter}
                onChange={handleFilterChange}
              >
                <option>All Vices</option>
                {categories.map(cat => (
                  <option key={cat.id} value={cat.name}>
                    {cat.name}
                  </option>
                ))}
              </Form.Control>
            </Form>
            {/* <CreateVice categories={categories} /> */}
          
        </Container>

        <Container>
          {categories ? renderCategories() : ''}
        </Container>
      </div>
    );

}



export default ViceContainer
